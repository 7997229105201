import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const CampaignForFoodAndRestaurants2 = ({ location }) => {
  const title =
    "【飲食集客アイデア】来店につながるTwitterインスタントウィンキャンペーンを無料で実施するには？〈活用例その2〉"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="飲食集客なら必見！Twitterインスタントウィンを無料で実施するには？"
          pagedesc="飲食店への集客につなげるTwitterインスタントウィンのアイデアを大公開。無料ツールを使って魅力的なプレゼント企画をコストを抑えて開催する方法をていねいに解説します。マーケティング担当者必見です。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/campaign-for-food-and-restaurants-2/campaign-for-food-and-restaurants-2.jpg"
          pagepath="/blog/campaign-for-food-and-restaurants-2"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は4分で読むことができます
            </div>
            <h1>
              【飲食集客アイデア】来店につながるTwitterインスタントウィンキャンペーンを無料で実施するには？〈活用例その2〉
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2023年3月5日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="タブレット画面に表示されているECサイトのアイコン"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/campaign-for-food-and-restaurants-2/campaign-for-food-and-restaurants-2.jpg"
                className="w-full"
              />
            </figure>
            <h2>
              メニュー紹介だけではもったいない！Twitterから実店舗への集客につなげるインスタントウィン企画
            </h2>
            <p>
              今では一般的となった、飲食店のTwitterアカウント運用。直接ファンとやりとりをしたり、こだわりのメニュー紹介の投稿を続けたりするのも、1つの手でしょう。
            </p>
            <p>
              しかし、企業のSNSキャンペーンを支援してきたPARKLoTとしては、”Twitterインスタントウィンキャンペーン”を開催し、「実際に店舗へ足を運んでもらう」「飲食店の売上に寄与する」可能性を、マーケティング担当者の方にはぜひ検討いただきたいと思っています。
            </p>
            <h2>
              無料インスタントウィンツールでできること、アイデアを全公開します！
            </h2>
            <p>
              高額のツール導入が必須だったインスタントウィンキャンペーンを、PARKLoT
              Liteでは「無料で」試すことができます。ただ、「どんな企画内容にするべき？」「アイデアが浮かばない」「キャンペーン自体初めて」とお悩みの担当者も多いと思います。
            </p>
            <p>
              そんな不安を解消すべく、無料インスタントウィン抽選ツールPARKLoT
              Liteを活用したキャンペーン例を、細かい設定まで全公開いたします。第2弾の本記事は、飲食店のSNS集客に特化しています。今回解説する事例を参考に、ぜひインスタントウィンキャンペーンを実施してみてください。
            </p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    無料でインスタントウィンを実施！PARKLoT Lite
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    簡単に開催可能！PARKLoT Liteのキャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    ツール導入でカンタン管理、業務コストも削減できる
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    【飲食店に特化】拡散と集客を両立するTwitterプレゼントキャンペーン例
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    Twitterインスタントウィンの開催フローと準備物
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id6" className="toc__link">
                    Twitterインスタントウィンで飲食店の集客を最大化！
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">無料でインスタントウィンを実施！PARKLoT Lite</h2>
              <p>
                一般的には高額な利用料がかかるインスタントウィンキャンペーンを、無料で開催できるのがPARKLoT
                Liteです。インスタントウィンの効果、ツール利用の便利さ、キャンペーン管理の効率化を実感いただける機能が揃っています。また、開催前の審査が必須で、セキュリティや個人情報についても、適切に運用しているので安心してご利用いただけます。
              </p>
              <p>
                PARKLoT Liteについて、
                <a
                  href="https://prtimes.jp/main/html/rd/p/000000016.000066114.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  プレスリリース
                </a>
                も公開されております。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/how-to-instant-win-campaign-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    初めてでもOK！インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.1
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id2">簡単に開催可能！PARKLoT Liteのキャンペーン</h2>
              <p>
                PARKLoT
                Liteでは、キャンペーン投稿内のURLから参加者自身が結果確認するフォロー＆リツイートキャンペーンが開催できます。もちろん当選者はPARKLoT
                Liteのシステムで自動抽選されます。URLで抽選結果を確認するフローにすることで、DM送信上限を心配する必要が無くなりました。
              </p>
              <img
                placeholder="tracedSVG"
                alt="ParkLot Liteインスタントウィン参加者のフロー"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/campaign-for-food-and-restaurants-2/campaign-for-food-and-restaurants-2-2.jpg"
                className="w-full"
              />
              <div className="blog__border-highlight flex">
                <p>
                  <span className="emoji">&#x1F4A1;</span>
                </p>
                <ol>
                  <li>設定時間に自動でキャンペーンツイートが投稿されます</li>
                  <li>
                    ユーザーはフォロー＆リツイートだけで応募完了 （設定確率に合わせてPARKLoT
                    Liteが自動で抽選）
                  </li>
                  <li>
                    ユーザーはキャンペーンツイート内のURLからその場で結果確認！
                  </li>
                </ol>
              </div>
              <p>
                また、無料利用にあたって開催タイプ・景品数・開催回数に制限を設けています。
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>開催タイプ</td>
                    <td>
                      インスタントウィン（URLから結果確認のみ、DM通知なし）
                    </td>
                  </tr>
                  <tr>
                    <td>景品数</td>
                    <td>1種類のみ</td>
                  </tr>
                  <tr>
                    <td>開催回数</td>
                    <td>3回まで</td>
                  </tr>
                </tbody>
              </table>
              <p>
                無料回数消化後は初期費用無料、月額1万円（税別）で、引き続きご利用いただけます。応募者や当選者のデータも保持されますので、ご安心ください。
              </p>
            </div>
            <div>
              <h2 id="id3">ツール導入でカンタン管理、業務コストも削減できる</h2>
              <p>
                PARKLoT
                Liteの管理画面は簡単に使いやすく、データも見やすい設計となっています。また、無料といっても簡単かつ効果的にTwitterキャンペーンを開催できるような機能を準備しています。
              </p>
              <table>
                <thead>
                  <tr>
                    <th className="blog__text-center">項目</th>
                    <th className="blog__text-center">可能な設定</th>
                    <th className="blog__text-center">便利な機能</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>基本情報</td>
                    <td>
                      ・キャンペーン名
                      <br />
                      ・開始日時
                      <br />
                      ・当選者のフィルタリング設定
                      <br />
                      など
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>ツイート</td>
                    <td>
                      ・ツイート文言
                      <br />
                      ・バナー画像
                    </td>
                    <td>実際の投稿画面を確認しながら入力できます。</td>
                  </tr>
                  <tr>
                    <td>プレゼント</td>
                    <td>
                      ・景品
                      <br />
                      ・当選者数
                      <br />
                      ・当選確率
                      <br />
                      ・住所やEメールアドレスの収集の有無
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>クリエイティブ</td>
                    <td>
                      ・当たり画面の文言と画像
                      <br />
                      ・ハズレ画面の文言と画像
                    </td>
                    <td>URLを記載し、サイトに誘導することができます。</td>
                  </tr>
                  <tr>
                    <td>応募者数</td>
                    <td>・キャンペーン開始後、応募者数とユーザーの確認</td>
                    <td>CSVでダウンロードすることも可能です。</td>
                  </tr>
                  <tr>
                    <td>当選者</td>
                    <td>・当選者数とユーザー、景品発送に伴う情報の確認</td>
                    <td>CSVでダウンロードすることも可能です。</td>
                  </tr>
                </tbody>
              </table>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="キャンペーン開催中の管理画面"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/campaign-for-ecsite/campaign-for-ecsite-3.png"
                maxwidth={640}
              />
              <small>（キャンペーン開催中の管理画面）</small>
            </div>
            <div>
              <h2 id="id4">
                【飲食店に特化】拡散と集客を両立するTwitterプレゼントキャンペーン例
              </h2>
              <p>
                架空の飲食店「美味ごはん屋」での開催を想定してPARKLoT
                Liteを利用したキャンペーン例をご紹介します。
              </p>
              <table>
                <tr>
                  <td className="font-bold">キャンペーン名</td>
                  <td>
                    アマギフが当たる！美味ごはん屋
                    特製春どんぶり発売記念キャンペーン！！
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">実施期間</td>
                  <td>7日間</td>
                </tr>
                <tr>
                  <td className="font-bold">景品</td>
                  <td>
                    ・参加者全員に温泉たまごトッピング券（店員に見せて使う）
                    <br />
                    ・Wチャンスとして後日Amazonギフトカード1,000円分
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">当選者数</td>
                  <td>
                    ・トッピング券1,000名
                    <br />
                    ・Amazonギフトカード1名
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">応募方法</td>
                  <td>フォロー＆リツイート</td>
                </tr>
                <tr>
                  <td className="font-bold">参加方法</td>
                  <td>
                    1.美味ごはん屋の公式アカウントをフォロー
                    <br />
                    2.対象ツイートをリツイート
                    <br />
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">結果通知方法</td>
                  <td>
                    ・対象ツイート内のURLから参加者が確認
                    <br />
                    ・Wチャンスは当選者のみDM送付
                    <br />
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">結果通知タイミング</td>
                  <td>
                    ・即時（インスタントウィン）
                    <br />
                    ・後日通知（Wチャンス）
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">意図</td>
                  <td>
                    ・新メニューの発売に合わせたキャンペーンです。特製春どんぶりの認知拡大やこだわりの発信につなげます。
                    <br />
                    ・店舗近隣に住むユーザーにピンポイントで来店を促すのは、SNS上ではどうしても困難です。そのため、対象ツイートが近隣ユーザーに届くまで拡散するよう、どのユーザーにも魅力的なAmazonギフトカードを景品設定に組み込みました。ターゲットにしたいユーザーにも、それ以外のユーザーにも喜んでもらえる景品設定をおすすめします。
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">効果</td>
                  <td>
                    ・応募はフォロー＆リツイートだけと簡単なので、高い参加率が期待できます。
                    <br />
                    ・店名、新メニューの宣伝がTwitter上で拡散されます。バナーや対象ツイート内に商品の紹介をのせるとさらに効果UP！
                    <br />
                    ・当選確率を1/1にしておき、参加者全員プレゼントにすることで、店に対してポジティブな印象を与えることができます。
                    <br />
                    ・また、結果メッセージ欄にLINE公式アカウントのおともだち登録へとつなげることもできます。
                  </td>
                </tr>
              </table>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="当選確率を1/1にすれば応募者全員にプレゼントも可能です。当選確率と当選数はキャンペーン中も変更できます。"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/campaign-for-ecsite/campaign-for-ecsite-4.png"
                maxwidth={640}
              />
              <small>
                （当選確率を1/1にすれば応募者全員にプレゼントも可能です。当選確率と当選数はキャンペーン中も変更できます。）
              </small>
            </div>
            <div>
              <h2 id="id5">Twitterインスタントウィンの開催フローと準備物</h2>
              <p>
                続いて、架空の飲食店「美味ごはん屋」でのキャンペーン開催に関して、フローと準備物をご説明します。
              </p>
              <table>
                <thead>
                  <tr>
                    <th className="blog__text-center">スケジュール</th>
                    <th className="blog__text-center">準備タスク</th>
                    <th className="blog__text-center">準備物</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>〜開始1週間前</td>
                    <td>
                      Twitterで美味ごはん屋アカウントにログインした状態で、
                      <a
                        href="https://promoter.park-lot.com/signup"
                        target="_blank"
                        rel="noreferrer"
                      >
                        PARKLoT Lite
                      </a>
                      に無料アカウント登録をする
                      <br />
                      その後、管理画面で新規キャンペーンを作成する
                    </td>
                    <td>
                      ①ツイート文
                      <br />
                      ②バナークリエイティブ
                      <br />
                      ③誘導するLINE公式アカウントURL
                    </td>
                  </tr>
                </tbody>
                <tr>
                  <td></td>
                  <td>（PARKLoTによるキャンペーン審査）</td>
                  <td></td>
                </tr>
                <tr>
                  <td>キャンペーン開始日</td>
                  <td>
                    （自動でツイートが投稿されるので、タスクはありません）
                    <br />
                    （自動抽選で、参加者がURLをタップすると結果画面が即時表示されます）
                  </td>
                </tr>
                <tr>
                  <td>終了後〜</td>
                  <td>
                    PARKLoT
                    Lite管理画面で参加者一覧を確認し、Amazonギフトカード当選者にDMをする
                  </td>
                  <td>④Amazonギフトコード</td>
                </tr>
              </table>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="（参加者一覧、当選者一覧のcsv出力が可能です。）"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/campaign-for-ecsite/campaign-for-ecsite-5.png"
                maxwidth={640}
              />
              <small>（参加者一覧、当選者一覧のcsv出力が可能です。）</small>
            </div>
            <div>
              <h2 id="id6">
                Twitterインスタントウィンで飲食店の集客を最大化！
              </h2>
              <p>
                PARKLoT
                LiteではTwitterユーザーが参加しやすいインスタントウィンを採用し、シンプルな機能と使いやすい管理画面でご担当者様のお手も煩わせません。コストを抑えながらTwitterで認知拡大を目指すならPARKLoT
                Liteが最適です。ぜひご活用ください。
              </p>
              <p>
                貴社のTwitterアカウントにログインした状態で、
                <a
                  href="https://promoter.park-lot.com/signup"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://promoter.park-lot.com/signup
                </a>
                からご利用ください。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/risks-of-campaign-implementation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プレゼントキャンペーンを社内で実施するリスクとその回避方法
                  </a>
                </p>
              </div>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="kakefuda" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/examples-of-restaurant"
                    target="_blank"
                    rel="noreferrer"
                  >
                    飲食店にはTwitterのクーポン配布キャンペーンがおすすめ！認知度&amp;来店客数アップに最適な理由を解説！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/campaign-for-food-and-restaurants"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【食品・飲食店特化】売上アップ！集客キャンペーン事例をわかりやすく解説
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/tips-for-sns-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【SNSキャンペーン】本気で販促につなげるなら！すぐできる10のポイント
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign-basic"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterプレゼント企画のやり方【まずはこれだけ！テンプレ付】基本編
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/risks-of-campaign-implementation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プレゼントキャンペーンを社内で実施するリスクとその回避方法
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default CampaignForFoodAndRestaurants2
